<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="12">
          <el-form-item label="练习类型">
            <el-radio-group v-model="queryPracticeType" size="small">
              <el-radio-button label="0">选择</el-radio-button>
              <el-radio-button label="1">填空</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="练习名称">
            <el-input v-model="queryPracticeName" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onQuery">查询</el-button>
          <el-button @click="onQuery">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table
      v-loading="loading"
      :data="practiceList"
      highlight-current-row
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      style="width: 100%"
    >
      <el-table-column prop="practiceName" label="名称"></el-table-column>
      <el-table-column
        prop="createTime"
        :formatter="formatTime"
        label="创建时间"
      ></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            size="small"
            @click="onEditPractice(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="onDeletePractice(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
import { practiceApi } from "@/api/practiceApi";
import { epochToDateString } from "@/util/TimeUtil";
import { PracticeTypeEnum } from "@/util/EnumUtil";
import { alertMessage } from "@/util/alertMessageUtil.js";
export default {
  data() {
    return {
      queryPracticeName: "",
      queryPracticeType: PracticeTypeEnum.CHOICE,
      practiceList: [],
      currentPage: 1,
      totalPage: 10,
      loading: false,
    };
  },
  components: {},
  mounted() {
    this.onQuery();
  },
  methods: {
    formatTime: function (row, column) {
      return epochToDateString(row[column.property]);
    },
    onChangePage(val) {
      this.currentPage = val;
      this.onQuery();
    },
    search() {
      practiceApi
        .searchPractice(this.queryPracticeType, this.queryPracticeName)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.practiceList = data;
            this.totalPage = totalPage;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    onQuery() {
      if (this.queryPracticeName) {
        this.search();
        return;
      }

      this.loading = true;
      practiceApi
        .listPractice(this.queryPracticeType, this.currentPage)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.practiceList = data;
            this.totalPage = totalPage;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    onEditPractice(index, row) {
      if (row.practiceType === PracticeTypeEnum.CHOICE) {
        this.$router.push({
          name: "choice-edit",
          params: { id: row.practiceId },
        });
      } else if (row.practiceType === PracticeTypeEnum.FILL_BLANK) {
        this.$router.push({
          name: "fillblank-edit",
          params: { id: row.practiceId },
        });
      }
    },
    onDeletePractice(index, row) {
      let msg = "确认删除该记录吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          practiceApi.deletePractice(row.practiceId).then((res) => {
            if (res.code === 0) {
              alertMessage("删除成功", "success");
              this.practiceList.splice(index, 1);
            } else {
              alertMessage(res.data.msg, "warning");
            }
          });
        })
        .catch(() => {});
    },
  },

  watch: {
    queryPracticeType: function (newVal, oldVal) {
      console.log(newVal, oldVal);
      this.onQuery();
    },
  },
};
</script>

<style lang="less" scoped></style>
