import { render, staticRenderFns } from "./practiceList.vue?vue&type=template&id=66decf68&scoped=true&"
import script from "./practiceList.vue?vue&type=script&lang=js&"
export * from "./practiceList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66decf68",
  null
  
)

export default component.exports